export const posters = [
  {
    src: '/posters/Allochiria_Summer2024.jpg',
    alt: 'Summer Tour 2024',
  },
  {
    src: '/posters/Allochiria_Commotion_Tourposter_October_017_final.jpg',
    alt: 'Commotion Tour October 2023',
  },
  {
    src: '/posters/Commotion Tour 2023 Allochiria with Their Methlab.jpg',
    alt: 'Commotion Tour May 2023',
  },
  {
    src: '/posters/europe_tour_2018.jpeg',
    alt: 'European tour 2018',
  },
  {
    src: '/posters/Allochiria_ryvulet_european tour 2015.jpg',
    alt: 'European tour 2015',
  },
  {
    src: '/posters/ROCKWAVE_RAVEN_2022.jpg',
    alt: 'ROCKWAVE festival 2022',
  },
  {
    src: '/posters/rockstadt_2023.jpg',
    alt: 'Rockstadt Extreme Fest 2023',
  },
  {
    src: '/posters/rockstadt_2018.jpg',
    alt: 'Rockstadt Extreme Fest 2018',
  },
  {
    src: '/posters/rockstadt_2014.jpg',
    alt: 'Rockstadt Extreme Fest 2014',
  },
];
