import styled from 'styled-components';
import { SectionHeader, TextContent } from './components';
import { epkLinks } from './data/epkLinks';
import { logos } from './data/logos';

export const EPKLogos = () => {
  const logosLink = epkLinks.find((i) => i.id === 'logos');

  const handleClickLogo = (imgLink) => {
    // trigger an instant download
    const a = document.createElement('a');
    a.href = imgLink;
    a.download = imgLink.split('/').pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // open it in a new tab too, if in desktop
    if (window.document.body.clientWidth >= 768) {
      window.open(imgLink, '_blank').focus();
    }
  };

  return (
    <>
      <SectionHeader>LOGO PACK</SectionHeader>
      <TextContent center>
        <p>
          Click on each item to download and preview separately. Alternatively,{' '}
          <a
            href={logosLink.link}
            title={logosLink.label}
            target="_blank"
            rel="noreferrer"
            style={{ color: 'black' }}
          >
            view and download all logos here
          </a>
          .
        </p>

        {logos.map((image) => (
          <LogoItem onClick={() => handleClickLogo(image.src)}>
            <Image
              key={image.src}
              src={image?.display || image.src}
              alt={image.alt}
            />
            <p>{image.alt}</p>
          </LogoItem>
        ))}
      </TextContent>
    </>
  );
};

const LogoItem = styled.div`
  display: block;
  border: 1px solid transparent;
  width: 50%;
  margin: 0 auto;
  cursor: pointer;

  > p {
    margin-top: 0;
    font-size: 0.75rem;
  }

  :hover {
    border: 1px solid black;
    text-style: underline;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Image = styled.img`
  max-height: 100px;

  @media only screen and (max-width: 768px) {
    margin-bottom: 1rem;
    max-height: 60px;
  }
`;
