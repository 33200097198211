import styled from 'styled-components';
import { Section, SectionHeader, Content } from './components';
import { videos } from './data/videos';
import { photos } from './data/photos';
import { epkLinks } from './data/epkLinks';

export const Media = ({ photosOnly = false, videoOnly = false }) => {
  const photosLink = epkLinks.find((i) => i.id === 'photos');

  const handleClickImg = (imgLink) => {
    // trigger an instant download
    const a = document.createElement('a');
    a.href = imgLink;
    a.download = imgLink.split('/').pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // open it in a new tab too, if in desktop
    if (window.document.body.clientWidth >= 768) {
      window.open(imgLink, '_blank').focus();
    }
  };

  return (
    <>
      <SectionHeader>
        {photosOnly ? 'LIVE PHOTOS' : videoOnly ? 'VIDEOS' : 'MEDIA'}
      </SectionHeader>
      <Section>
        <Content>
          {photosOnly && (
            <p>
              Click on each photo to download separately. Alternatively,{' '}
              <a
                href={photosLink.link}
                title={photosLink.label}
                target="_blank"
                rel="noreferrer"
                style={{ color: 'black' }}
              >
                download all photos here
              </a>
              .
            </p>
          )}
          {!photosOnly &&
            videos.map((video) => (
              <Video
                key={video.title}
                height="415"
                src={video.link}
                title={video.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            ))}
          {!videoOnly &&
            (photosOnly ? photos.slice(5) : photos).map((image) => (
              <Image
                epk={photosOnly}
                key={image.src}
                src={image.src}
                alt={image.alt}
                onClick={
                  photosOnly && image?.src
                    ? () => handleClickImg(image?.src)
                    : () => null
                }
              />
            ))}
        </Content>
      </Section>
    </>
  );
};

const Video = styled.iframe`
  margin-bottom: 2rem;
  width: 100%;
  max-width: 800px;
`;

const Image = styled.img`
  ${({ epk }) => (epk ? 'cursor: pointer;' : '')}
  margin-bottom: 2rem;
  width: 100%;
  max-width: 800px;

  @media only screen and (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;
