import styled from 'styled-components';

export const TextContent = styled.div`
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  max-width: 50%;
  margin: 0 auto;

  @media only screen and (max-width: 768px) {
    max-width: 90%;
  }
`;
