import styled from 'styled-components';

export const ContactEMG = () => {
  return (
    <>
      <p>Extreme Management Group, Inc.</p>
      <p>Colin Parks</p>
      <MailLink href="mailto:emg.colin.parks@gmail.com">
        emg.colin.parks@gmail.com
      </MailLink>
    </>
  );
};

const MailLink = styled.a`
  color: black;
  margin-left: 0.375rem;

  @media only screen and (max-width: 768px) {
    width: 100%;
    display: inline-block;
    margin-left: unset;
  }
`;
