/**
 * Rendered only in the /epk page!
 */
import styled from 'styled-components';
import { TextContent } from './components';
import { epkLinks } from './data/epkLinks';

export const EPKBandBio = () => {
  const file = epkLinks.find((i) => i.id === 'bio');
  return (
    <TextContent>
      <p>Allochiria is a metal band formed in Athens, Greece in 2008.</p>
      <p>
        With a fine balance of post-metal, sludge, progressive and hardcore
        elements, their music is thematic; it revolves around humans as social
        beings, the corruption that defines them in modern societies, their vain
        struggles, painful routines and the consequences of their actions in the
        world around them.
      </p>
      <p>
        Allochiria have released one EP (2010) and three full-length albums:{' '}
        <b>Omonoia</b> (2014), <b>Throes</b> (Art of Propaganda, 2017) and{' '}
        <b>Commotion</b> (Venerate Industries, 2023).
      </p>
      <p>
        The band has a long history of countless live shows and festivals
        throughout the years, alongside multiple European tours. They've shared
        the stage with Botch, Social Distortion, Amenra, Deafheaven, Altar of
        Plagues, Harakiri for the Sky, Deaf Kids, and more. They have played in
        Desertfest Athens, Rockstadt Extreme Fest in Romania and Rockwave
        Festival Athens.
      </p>
      <p>
        Currently Allochiria are working on their 4th studio album and getting
        ready for their next European tour.
      </p>
      {/* <h4> history </h4>
      <p>
Allochiria released their first EP in 2010 after winning a "Battle of
the Bands" contest. Shortly after, the lineup changed to include Eirini
in the vocals and Stef in the drums, which led to lots of live shows and
eventually the release of their first studio album, <b>Omonoia</b>{' '}
(2014, self-released). Omonoia put Allochiria prominently in the
post-metal scene and grew hugely their audience outside the borders of
Greece.
</p>
<p>
This was the beginning of many live shows to come and the band's first
European tour in March 2015, totaling 11 shows across the Balkans and
central Europe.
</p>
<p>
The band's second album followed up next, <b>Throes</b>, released by Art
of Propaganda in 2017. While the band continued touring in Greece and
Europe, growing their audience and writing new music, the Covid pandemic
hit. This resulted in the cancelation of what would have been their
lengthiest European tour yet, with 15 shows, 13 of which got cancelled.
</p>
<p>
Allochiria's big return after the Covid pandemic was with a sold out
show in Athens, in May 2022, and was solidified with the recordings of
their third and latest studio album, <b>Commotion</b>, released by the
Greek record label Venerate Industries in 2023.
</p>
<p>
Billy joined the lineup as the third guitar, and the band continued to
tour extensively with 3 more European tours in 2023 and 2024, and open
shows for big bands in Greece (Social Distortion, Botch, Amenra).
</p>
<p>
The present finds Allochiria working on their 4th studio album and
having partnered with Extreme Management Group they are ready to ROCK
YOUR SOCKS OKAY this is too big.
</p> */}

      <p>
        <Link
          href={file.link}
          title={file.label}
          target="_blank"
          rel="noreferrer"
        >
          Download biography (pdf)
        </Link>
      </p>
      <p>
        <Link href="/shows-archive" target="_blank" rel="noreferrer">
          Full list of Allochiria's past shows (opens new browser tab)
        </Link>
      </p>
    </TextContent>
  );
};

const Link = styled.a`
  color: black;
`;
