import styled from 'styled-components';
import { Section } from './components/Section';
import { Masthead } from './Masthead';
import { EPKBandBio } from './EPKBandBio';
import { BandPhoto } from './BandPhoto';
import { Media } from './Media';
import { Discography } from './Discography';
import {
  SectionHeader,
  TextContent,
  ContactMedia,
  ContactEMG,
} from './components';
import { epkLinks } from './data/epkLinks';
import { PressPhotos } from './PressPhotos';
import { EPKLogos } from './EPKLogos';
import { EPKTourPack } from './EPKTourPack';

export const EPKNew = () => {
  const photosLink = epkLinks.find((i) => i.id === 'photos');

  return (
    <>
      <Masthead isEPK />
      <BandPhoto />
      <AllochiriaLogo
        src="allochiria_logo_trans_black.png"
        alt="Allochiria logo"
      />
      <Section id="press-photos">
        <PressPhotos />
      </Section>
      <Section id="biography">
        <SectionHeader>BIOGRAPHY</SectionHeader>
        <EPKBandBio />
      </Section>
      <Section id="discography">
        <Discography full />
      </Section>
      <Section id="videos">
        <Media videoOnly />
        <TextContent center>
          See all videos in Allochiria's Youtube channel:{' '}
          <Link
            href={'https://www.youtube.com/@Allochiriatheband'}
            title={'@Allochiriatheband'}
            target="_blank"
            rel="noreferrer"
          >
            @Allochiriatheband
          </Link>
        </TextContent>
      </Section>
      <Section id="live-photos">
        <Media photosOnly />
        <TextContent center>
          <Link
            href={photosLink.link}
            title={photosLink.label}
            target="_blank"
            rel="noreferrer"
          >
            Download all photos
          </Link>
        </TextContent>
      </Section>
      <Section id="logos">
        <EPKLogos />
      </Section>
      <Section id="tour">
        <EPKTourPack />
      </Section>
      <Section id="contact">
        <SectionHeader>CONTACT</SectionHeader>
        <TextContent center>
          <ContactEMG />
        </TextContent>
      </Section>
      <Section id="social-media">
        <SectionHeader>SOCIAL MEDIA</SectionHeader>
        <ContactMedia />
      </Section>
    </>
  );
};

const Link = styled.a`
  color: black;
`;

const AllochiriaLogo = styled.img`
  width: 800px;
  display: block;
  margin: 16px auto 0px;

  @media only screen and (max-width: 768px) {
    margin: 0 auto;
    width: 350px;
  }
`;
