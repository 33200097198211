export const menuItemsEPK = [
  {
    id: '/epk#press-photos',
    label: 'PRESS PHOTOS',
  },
  {
    id: '/epk#biography',
    label: 'BIOGRAPHY',
  },
  {
    id: '/epk#discography',
    label: 'DISCOGRAPHY',
  },
  {
    id: '/epk#videos',
    label: 'VIDEOS',
  },
  {
    id: '/epk#live-photos',
    label: 'LIVE PHOTOS',
  },
  {
    id: '/epk#logos',
    label: 'LOGO PACK',
  },
  {
    id: '/epk#tour',
    label: 'TOUR PACK',
  },
  {
    id: '/epk#contact',
    label: 'CONTACT',
  },
  {
    id: '/epk#social-media',
    label: 'SOCIAL MEDIA',
  },
];
