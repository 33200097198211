import styled from 'styled-components';
import { Section, SectionHeader, Content } from './components';
import { photos } from './data/photos';
import { epkLinks } from './data/epkLinks';

export const PressPhotos = () => {
  const photosLink = epkLinks.find((i) => i.id === 'photos');

  const handleClickImg = (imgLink) => {
    // trigger an instant download
    const a = document.createElement('a');
    a.href = imgLink;
    a.download = imgLink.split('/').pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // open it in a new tab too, if in desktop
    if (window.document.body.clientWidth >= 768) {
      window.open(imgLink, '_blank').focus();
    }
  };

  return (
    <>
      <SectionHeader>{'PRESS PHOTOS'}</SectionHeader>
      <Section>
        <Content>
          <p>
            Click on each photo to download and preview separately.
            Alternatively,{' '}
            <Link
              href={photosLink.link}
              title={photosLink.label}
              target="_blank"
              rel="noreferrer"
            >
              download all photos here
            </Link>
            .
          </p>
          {photos.slice(0, 5).map((image) => (
            <Image
              key={image.src}
              src={image.src}
              alt={image.alt}
              onClick={() => handleClickImg(image.src)}
            />
          ))}
          <Image
            key="band-photo"
            src="/alossi_lowres-8.jpg"
            alt={'Allochiria band photo'}
            onClick={() => handleClickImg('/alossi_lowres-8.jpg')}
          />
        </Content>
      </Section>
    </>
  );
};

const Link = styled.a`
  color: black;
`;

const Image = styled.img`
  cursor: pointer;
  margin-bottom: 2rem;
  width: 100%;
  max-width: 1000px;

  @media only screen and (max-width: 768px) {
    margin-bottom: 1rem;
  }
`;
