import styled from 'styled-components';
import { UltragrimCreds } from './UltragrimCreds';

export const AlbumDetails = ({ albumInfo, full }) => {
  const {
    coverArt,
    title,
    year,
    iframe,
    label,
    buyLink,
    credits,
    ultragrim,
    preorder,
  } = albumInfo;

  return (
    <AlbumWrapper>
      <CoverWrapper>
        <img src={coverArt.src} alt={coverArt.alt} />
      </CoverWrapper>
      <Details>
        <Title>{`${title} (${year})`}</Title>
        <iframe
          title={iframe.title}
          style={
            full
              ? { border: 0, width: '100%', height: '240px' }
              : { border: 0, width: '100%', height: '42px' }
          }
          src={full ? iframe.full : iframe.src}
          seamless
        >
          <a href={iframe.link}>{iframe.linkTitle}</a>
        </iframe>
        {preorder ? (
          <Link href={buyLink} target="_blank" rel="noopener">
            Preorder here
          </Link>
        ) : (
          <Link href={buyLink} target="_blank" rel="noopener">
            Listen or buy full album
          </Link>
        )}
        <AlbumCredits style={full ? { marginTop: '16px' } : {}}>
          {credits.map((cred) => (
            <p key={cred}>{cred}</p>
          ))}
          {ultragrim && <UltragrimCreds />}
          {label && (
            <p>
              {preorder ? 'To be released by ' : 'Released by '}
              <a href={label.link} target="_blank" rel="noopener noreferrer">
                {label.name}
              </a>
            </p>
          )}
        </AlbumCredits>
      </Details>
    </AlbumWrapper>
  );
};

const AlbumWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;

  @media only screen and (max-width: 1280px) {
    flex-direction: column;
    margin-bottom: 3rem;
  }
`;

const CoverWrapper = styled.div`
  flex: 1;

  > img {
    width: 500px;

    @media only screen and (max-width: 1440px) {
      width: 100%;
    }
  }
`;

const Details = styled.div`
  flex: 1;

  @media only screen and (min-width: 769px) {
    margin-left: 1rem;
  }
`;

const Title = styled.h3`
  margin-top: 0;
  text-align: center;
`;

const Link = styled.a`
  color: black;
  text-align: center;
  margin: 4px 0;
  display: inline-block;
  width: 100%;
`;

const AlbumCredits = styled.div`
  font-size: 14px;
  text-align: center;
  margin-top: 1rem;

  @media only screen and (min-width: 1280px) {
    margin-top: 2.5rem;
  }

  p {
    margin: 8px 0;
  }

  a {
    color: black;
  }
`;
