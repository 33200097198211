import styled from 'styled-components';
import { TextContent } from './components';

export const BandBio = () => {
  return (
    <div>
      <AllochiriaLogo
        src="allochiria_logo_trans_black.png"
        alt="Allochiria logo"
      />
      <TextContent>
        <p>Allochiria is a metal band formed in Athens, Greece in 2008.</p>
        <p>
          They have released one EP (2010) and three full-length albums:{' '}
          <b>Omonoia</b> (2014), <b>Throes</b> (Art of Propaganda, 2017) and{' '}
          <b>Commotion</b> (Venerate Industries, 2023).
        </p>
        <p>
          The band has played countless live shows and festivals throughout the
          years, alongside multiple European tours. They've shared the stage
          with Amenra, Botch, Social Distortion, Deafheaven, Year of No Light,
          Altar of Plagues, Toundra, Minsk, Harakiri for the Sky, Deaf Kids, and
          more. They have played in Desertfest Athens, Rockstadt Extreme Fest in
          Romania and Rockwave Festival Athens. The band is actively booking new
          shows for the coming months and more tours and festivals will be
          announced soon.
        </p>
        <p>
          Allochiria’s music is thematic; it revolves around humans as social
          beings, the corruption that defines them in modern societies, their
          vain struggles, painful routines and the consequences of their actions
          in the world around them.
        </p>
      </TextContent>
    </div>
  );
};

const AllochiriaLogo = styled.img`
  width: 500px;
  display: block;
  margin: 16px auto;

  @media only screen and (max-width: 768px) {
    margin: 0 auto;
    width: 300px;
  }
`;
